
@import './styles/font.css';

body {
  /* background-color:  #f6faff; */
  background-color: #fafafa
 }


 @tailwind base;
 @tailwind components;
 @tailwind utilities;