.text-small{
    font-size:9px;
}

/* .height_custom{
  height: calc(100%);
} */

@media screen and (max-width: 640px) {
  .text-small{
      font-size:6px;
  }
}

@media  screen and (min-width:900px) {
  /* .go_to_cart{
    margin-left: 120px;
  } */
}