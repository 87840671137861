@font-face {
    font-family: 'Montserrat-Regular';
    src: 
        
         url('./fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display:swap;
}

body{
    font-family : 'Montserrat-Regular' !important;
}
.text-xxs{
    font-size:0.65rem;
}
.border-primary{
    border-color:#9C1E60
}